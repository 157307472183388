import React from 'react'
import Navbar from './Navbar'
import Home from './Home'
import About from './About'
import Technology from './Technology'
import Clients from './Clients'
import Footer from './Footer'
import Services from "./Services"
import OurProduct from './OurProducts/OurProduct'

const Index = () => {
  return (
    <div className=''>
     <Navbar />
     <div className='w-6/6'>
      <Home />
      <About />
      <Technology />
      <Services />
      <OurProduct/>
      <Clients/>
      </div>
      <Footer />
    </div>
  )
}

export default Index