import React from 'react';
import cloud from "../../assets/document-management.jpg";
import hospital from "../../assets/hosptal.png";
import crm from "../../assets/crm.jpg";
import school from "../../assets/school.png";
import learning from "../../assets/lms.jpg";
import social from "../../assets/social-media-management .jpg";

const OurProduct = () => {
  return (
    <><section className="bg-gradient-to-r from-white via-gray-50 to-gray-100 py-16" id="Product">
          <div className="container mx-auto px-6">
              <h2 className="text-4xl font-extrabold text-center text-royal-blue mb-12">Our Products</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                  {[{
                      image: cloud,
                      title: "Cloud Document Management",
                      description: "Securely stores and organizes digital files in the cloud, enabling easy access, collaboration, and automated workflows, all while ensuring data protection and efficiency.",
                      bgColor: "bg-blue-100"
                  },
                  {
                      image: hospital,
                      title: "Hospital Management",
                      description: "Streamlines operations, from patient records to billing and scheduling, improving efficiency, care quality, and resource management.",
                      bgColor: "bg-green-100"
                  },
                  {
                      image: school,
                      title: "School Management",
                      description: "Automates attendance, grading, scheduling, and communication, streamlining operations and improving collaboration.",
                      bgColor: "bg-yellow-100"
                  },
                  {
                      image: learning,
                      title: "Learning Management",
                      description: "Delivers and tracks educational content, facilitating online courses, assessments, and progress monitoring to enhance learning and training experiences.",
                      bgColor: "bg-purple-100"
                  },
                  {
                      image: crm,
                      title: "Customer Relationship Management",
                      description: "Centralizes customer data, automates sales and marketing tasks, and enhances customer interactions to boost engagement and drive business growth.",
                      bgColor: "bg-pink-100"
                  },
                  {
                      image: social,
                      title: "Social Media Management",
                      description: "Schedules, publishes, and analyzes content across platforms, helping businesses manage their online presence and engage with audiences effectively.",
                      bgColor: "bg-indigo-100"
                  }
                  ].map((product, index) => (
                      <div key={index} className={`${product.bgColor} relative rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 duration-300`}>
                          <div className="relative z-10 p-8 text-center">
                              <img src={product.image} alt={product.title} className="w-56 h-56  rounded-3xl mx-auto mb-4 shadow-lg" />
                              <h3 className="text-2xl font-bold text-gray-900">{product.title}</h3>
                              <p className="mt-4 text-gray-700">{product.description}</p>
                          </div>
                      </div>
                  ))}
              </div>
          </div>
      </section><div
          className="text-4xl mb-5 pt-7 flex justify-center font-inter font-bold text-royal-blue mt-4"
      >
              Our Client{" "}
          </div></>
  );
};

export default OurProduct;
